var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f8ab450693435902543aaaa553547daf6adab98c"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import contextBridgePkg from 'context-bridge-api/package.json';
const clientVersion = contextBridgePkg.version;

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
if (dsn) {
  const isPrivateCloud = Boolean(
    process.env.NEXT_PUBLIC_BACKEND_SUBDOMAIN_INPUT_REQUIRED,
  );
  const environment = isPrivateCloud
    ? 'private-cloud'
    : process.env.NEXT_PUBLIC_ENV;
  console.info('Starting up sentry ' + environment);
  Sentry.init({
    dsn,
    tracesSampleRate: 1,
    debug: false,
    environment,
    release: clientVersion,
  });
} else {
  console.info('Sentry disabled');
}
